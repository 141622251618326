'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'far';
var iconName = 'edge-pin';
var width = 610;
var height = 512;
var ligatures = [];
var unicode = '';
var svgPathData = 'M608 241.16C598.88 106.7 466.82.04 305.25.04c-75.3 0-144.17 23.17-197.19 61.51L49.72 4.02c-5.53-5.45-14.39-5.35-19.8.22-5.41 5.58-5.31 14.52.22 19.97l55.8 55.02C37.72 121.85 6.75 178.48 2.5 241.16 1.95 249.2 8.41 256 16.48 256c7.4 0 13.46-5.76 13.97-13.14 3.84-55.62 31.87-105.95 75.48-143.9l97.16 95.81c-13.8 20.37-21.87 44.93-21.87 71.33 0 70.31 57.2 127.51 127.51 127.51 27.04 0 52.13-8.47 72.79-22.88l139.17 137.25c2.72 2.69 6.26 4.02 9.79 4.02s7.27-1.42 10.01-4.25c5.41-5.58 5.31-14.52-.22-19.97L402.74 352.15c20.79-22.7 33.5-52.92 33.5-86.05 0-70.31-57.2-127.51-127.51-127.51-33.77 0-64.52 13.2-87.35 34.71l-93.07-91.78c47.88-33.36 109.62-53.49 176.94-53.49 146.44 0 266.54 95.21 274.8 214.82.51 7.38 6.57 13.14 13.97 13.14 8.06 0 14.52-6.8 13.98-14.84ZM308.73 365.61c-54.87 0-99.51-44.64-99.51-99.51 0-18.73 5.2-36.26 14.24-51.24l137.69 135.78c-15.23 9.48-33.2 14.96-52.42 14.96Zm0-199.01c54.87 0 99.51 44.64 99.51 99.51 0 25.49-9.63 48.76-25.45 66.38L241.32 192.98c17.74-16.37 41.43-26.38 67.41-26.38Z';

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [
    width,
    height,
    ligatures,
    unicode,
    svgPathData
  ]};

exports.faEdgePin = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = ligatures;
exports.unicode = unicode;
exports.svgPathData = svgPathData;