'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'fas';
var iconName = 'edge-ext';
var width = 512;
var height = 512;
var ligatures = [];
var unicode = '';
var svgPathData = 'M463.2 24c13.67 0 24.8 11.13 24.8 24.8v73.1c0 .22-.18.4-.4.4h-12.23c-2.92 0-5.88.15-8.78.46-22.56 2.35-42.85 13.34-57.14 30.94s-20.86 39.71-18.53 62.26c2.17 21.43 12.75 41.05 29.8 55.25 16.24 13.54 36.9 20.99 58.18 20.99h8.7c.22 0 .4.18.4.4v73.3c-.06 13.51-11.09 24.5-24.6 24.5H341.81V451c0 20.4-16.6 37-37 37-9.9 0-19.19-3.85-26.17-10.85-6.95-6.97-10.76-16.23-10.73-26.08v-60.48h-121.5c-13.67 0-24.8-11.13-24.8-24.8v-121.5h-60.6c-20.4 0-37-16.6-37-37s16.6-37 37-37h60.5V48.8c0-13.67 11.13-24.8 24.8-24.8h73.1c.22 0 .4.18.4.4v12.19c0 2.93.15 5.9.46 8.82 2.19 21.01 12.05 40.35 27.77 54.44 15.61 13.98 35.71 21.69 56.6 21.69 2.93 0 5.89-.15 8.81-.46 21.43-2.17 41.06-12.75 55.27-29.8 13.54-16.24 20.99-36.9 20.99-58.18v-8.7c0-.22.18-.4.4-.4h73.1m-.01-24h-73.1c-13.48 0-24.4 10.92-24.4 24.4v8.7c0 31.9-22.9 60.9-54.7 64.1-2.14.22-4.27.33-6.37.33-30.81 0-57.24-23.29-60.5-54.6-.22-2.1-.33-4.21-.33-6.33V24.4c0-13.48-10.92-24.4-24.4-24.4h-73.1c-26.95 0-48.8 21.85-48.8 48.8v97.5H61c-33.69 0-61 27.31-61 61s27.31 61 61 61h36.6v97.5c0 26.95 21.85 48.8 48.8 48.8h97.5V451c-.1 33.7 27.2 61 60.9 61s61-27.31 61-61v-36.6H463.4c26.75 0 48.49-21.63 48.6-48.4V292.6c0-13.48-10.92-24.4-24.4-24.4h-8.7c-31.9 0-60.9-22.9-64.1-54.7-3.48-33.45 20.82-63.39 54.27-66.87 2.09-.22 4.2-.33 6.3-.33h12.23c13.48 0 24.4-10.92 24.4-24.4V48.8c0-27-21.8-48.8-48.8-48.8Z';

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [
    width,
    height,
    ligatures,
    unicode,
    svgPathData
  ]};

exports.faEdgeExt = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = ligatures;
exports.unicode = unicode;
exports.svgPathData = svgPathData;