// offers.js

'use strict';

var miuri = require('Vendor/miuri.js').miuri;

const Countdown = require("Scripts/common/countdown.js"); // Seasonal

module.exports = Offers;

function Offers() {
	this.init = function() {
		this.ui = {
			rootElement: $('.js-voucher-list'),
			content: $('.js-voucher-list-content'),
			errorMessage: $('.js-voucher-list-error'),
			loadingIndicator: $('.js-loading-indicator')
		};

		this.state = {...new miuri().query(), ...this.ui.rootElement.data()};
		this.bindEventHandlers();
		this.countdown(); // Seasonal
	}

	this.bindEventHandlers = function() {
		$('.js-select-page').on('click', this.onClickPagination.bind(this));
	}

	this.onClickPagination = function(e) {
		e.preventDefault();
		var element = $(e.currentTarget);
		// Grab the page number to fetch from the element that was clicked.
		var page = element.data('page');
		console.log("Offers.onClickPagination(page=" + page + ")");

		// Before the request is made, show a loading indicator.
		this.ui.content.hide();
		this.ui.loadingIndicator.show();

		// Call the API and handle the response.
		this.getVouchers({ page: page })
			.then(function(resp) {
				if(resp.length) {
					self.onSuccess(resp);
				}
				else {
					self.onFailure();
				}
			})
			.always(function() {
				// Regardless of outcome, always hide the loading indicator when the request is finished.
				self.ui.loadingIndicator.hide();
			});
	}

	// Makes the actual request to the voucher API
	this.getVouchers = function(options) {
		console.log("Offers.getVouchers");
		self.state = {...self.state, ...(options || {})};
		return $.get('/offers/ajax', self.state) ;
	}

	// Invoked when the AJAX request succeeds.
	this.onSuccess = function(response) {
		console.log("Offers.onSuccess");
		// Replace the existing vouchers / pagination
		self.ui.rootElement.html(response).show();

		// Rebind the event handlers, this wouldn't be need if we used $(document).on('click', 'selector', ...), aka. event delegation.
		self.updateUrl();
		self.bindEventHandlers();

		// Scroll back up
		$(document).scrollTop($(".js-voucher-list").offset().top - 100);
	}

	// Invoked when the AJAX request fails, for example if the server responds with a 4xx or 5xx status, or the request never completes.
	this.onFailure = function(error) {
		console.log("Offers.onFailure");
		self.ui.content.hide();
		self.ui.errorMessage.show();
	}

	// Updates the URL based upon the filter that's been selected.
	this.updateUrl = function() {
		var newUrl = new miuri();
		newUrl.query('page', self.state.page || 1);
		window.history.pushState({}, '', newUrl.toString());
	}

	// Countdown to Christmas day!
	this.countdown = function() {
		console.log("Christmas.countdown()");

		var countdown = $('.bf-countdown');
		var year      = countdown.data("year");
		var month     = countdown.data("month");
		var day       = countdown.data("day");

		var cd = new Countdown({
			cont: countdown.get(0),
			countdown: true,
			date: {
				day: day,
				month: month,
				year: year,
			},
			outputTranslation: {
				day: 'Days',
				hour: 'Hours',
				minute: '<span class="d-none d-md-inline">Minutes</span><span class="d-md-none">Mins</span>',
				second: '<span class="d-none d-md-inline">Seconds</span><span class="d-md-none">Secs</span>'
			},
			endCallback: function() {
				// do something awesome
			},
			outputFormat: 'day|hour|minute|second',
		});

		if(typeof cd.options.cont !== undefined && $(cd.options.cont).length){
			console.log("Christmas.countdown().start");
			cd.start();
		}
	}

	var self = this;
	self.init();
}
